import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* center items horizontally, in this case */
  height: 100vh;
  background: linear-gradient(250deg, #ae5bd6 25%, #4052f0 100%);
`;

export const Content = styled.div`
  display: block;
  justify-content: center;
  align-items: center; /* for single line flex container */
  align-content: center; /* center items horizontally, in this case */

  ul {
    display: flex;
    justify-content: center;

    list-style: none;
    margin-top: 44px;

    li {
      & + li {
        margin-left: 34px;
      }
    }
  }
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* for single line flex container */
  align-content: center; /* center items horizontally, in this case */

  height: 44vh;
  width: 44vh;

  background-color: #4456fb; //#fa4969;
  border-radius: 50%;

  span {
    color: #fff;
    font-size: 20vh;
    font-family: 'Roboto', sans-serif;
  }
`;

export const ButtonContainers = styled.div``;
