import React from 'react';

import Default from './pages/default';

import GlobalStyle from './styles/global';

const App: React.FC = () => (
  <>
    <Default />
    <GlobalStyle />
  </>
);

export default App;
