import React from 'react';
import { useSpring, animated } from 'react-spring';
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';

import imgRocketseat from '../../assets/images/rocketseat.svg';

import { Container, Content, Avatar } from './style';

const Default: React.FC = () => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const calc = (x: number, y: number) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
  ];
  const trans = (x: string, y: string, s: string) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  return (
    <>
      <Container>
        <Content>
          <animated.div
            onMouseMove={({ clientX: x, clientY: y }) => {
              set({ xys: calc(x, y) });
            }}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line react/destructuring-assignment
            style={{ transform: props.xys.interpolate(trans) }}
          >
            <Avatar>
              <span>DS</span>
            </Avatar>
          </animated.div>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/danilosalvador/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillLinkedin size={50} color="#fff" />
              </a>
            </li>
            <li>
              <a
                href="http://github.com/danilosalvador/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillGithub size={50} color="#fff" />
              </a>
            </li>
            <li>
              <a
                href="https://app.rocketseat.com.br/me/danilosalvador/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={imgRocketseat}
                  alt="Rocketseat"
                  style={{ height: 50, width: 50 }}
                />
              </a>
            </li>
          </ul>
        </Content>
      </Container>
    </>
  );
};

export default Default;
